









import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class CoachUserIndex extends Vue {
  private async handleLogout() {
    Vue.prototype.$Cookies.remove('Keep_Token', {path: '/'})
    localStorage.removeItem('Keep_OpenId')
    localStorage.removeItem('Keep_NickName')
    localStorage.removeItem('Keep_FaceUrl')
    localStorage.removeItem('Keep_ShipType')

    this.$toast('退出成功')
    location.reload()
  }
}
